interface Menu {
  name: string;
  path: string;
  outsite?: boolean,
  children?: Menu[];
}

const menu: Menu[] = [
  {
    name: 'Prompt Library',
    path: '/chatgpt-prompt-generator',
    outsite: false,
    children: [
      {
        name: 'ChatGPT Prompts',
        path: '/chatgpt-prompt-generator',
        outsite: true
      },
      {
        name: 'GPT-4o Prompts',
        path: '/chatgpt-prompt-generator/gpt-4-prompts/',
        outsite: true
      },
      {
        name: 'Midjourney Prompts',
        path: '/chatgpt-prompt-generator/chatgpt-prompts-for-midjourney/midjourney-prompt-guide',
        outsite: true
      }]
  },
  {
    name: 'GPTs',
    path: '/best-gpts/arvin-gpts',
    outsite: false
  },
  {
    name: 'Code Interpreter',
    path: '/arvin-code-interpreter',
    outsite: false
  },
  {
    name: 'Blog',
    path: '/blog/',
    outsite: true
  },
  {
    name: 'Pricing',
    path: '/user/subscription',
    outsite: false
  },
  {
    name: 'Instructions',
    path: '/arvin-instruction',
    outsite: false
  }
];

const menuInCodeInterpreter: Menu[] = [
  {
    name: 'Code Interpreter',
    path: '/code-interpreter',
    outsite: false
  },
  {
    name: 'Instructions',
    path: '/how-to-use-code-interpreter/',
    outsite: true
  }
];

const getMenu = () => {
  return menu;
};

const getMenuInCodeInterpreter = () => {
  return menuInCodeInterpreter;
};

const handleToDashboard = () => {
  // if (document && document.getElementById('arvinComponent')) {
  //   window.postMessage({ type: 'openOptionsPage', menu: 'general' }, '*');
  //   // compatible version 1.0.2 plug-in logic without receiving a message
  //   const timer = setTimeout(() => {
  //     navigateTo('/user/dashboard');
  //   }, 1000);
  //   window.addEventListener('message', function (event) {
  //     if (event.data === 'received openOptionsPage') {
  //       clearTimeout(timer);
  //     }
  //   });
  // } else {
    navigateTo('/user/dashboard');
  // }
};

export function useNavigation () {
  return {
    getMenu,
    getMenuInCodeInterpreter,
    handleToDashboard
  };
}
