<template>
  <div id="webappHeader" class="relative cp c-white rounded-16px flex-center hover:opacity-80 px-8px lg:px-16px h-24px lg:h-32px " @click="goToWebapp">
    <nuxt-icon name="website" class="fs-12 lg:fs-16" :filled="true"></nuxt-icon>
    <span class="ml-6px fs-12 font-600 lg:fs-14">Webapp</span>
  </div>
</template>

<script setup lang="ts">
	const goToWebapp = async () => {
		await reportEvent('Webapp_Click', {
			entry_type: 'website_homepage_navigation'
		});
		window.location.href = '/en/home';
	};
</script>

<style lang="less" scoped>
	#webappHeader {
		background: linear-gradient(294deg, #6165F6 37%, #9D61F6 91%);
		.guide-box {
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
		}
	}
</style>
